
.footer-con {
    margin-top:auto;
    background-color: rgb(51, 51, 51);
    padding:0em 1vw;
    font-size:0.8em;
    height:fit-content;
}

.footer-divider{
    border-bottom:0.5px solid white;
    margin-top:1.5em;
}

.footer-top{
    padding-top:1em;
}

.footer-rows{
    padding:1em 0vw;
}

.footer-con{
    color:white;
}

.footer-link-top , .footer-licensing>a{
    border-bottom:0.5px solid white;
    padding-bottom:3px;
    width:fit-content;
    color:white;
    font-size:var(--font-footer-link)
}


.footer-link-divider{
    padding:0 1em;
}



.row-txt{
    font-size:1em;
    opacity: 80%;
}

.row-txt:hover{
    opacity: 100%;
}

.in{
    display: inline-block;
}