.about-con {
    padding:2em 0;
}

.about-p {
    font-size: var(--font-200)
}

/* Exp & Mission CSS */

.about-exp-con,
.about-mission-con {
    padding: 2em clamp(5vw, 10vw ,40vw);
    text-align: justify;
    width:100%;

}

.about-exp-title,
.about-mission-title {
    font-size: var(--font-300);
    font-weight: 600;
    margin-bottom: 0.5em;
}