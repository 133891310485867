.services-pads {
    padding:5em clamp(1em , 2vw, 20vw);
}


.toc-ol{
    counter-set:toc-inc;
    list-style: none;
}


