.season-con {
    margin: 2em;
    width: clamp(15em, 45vw, 40em)
}

.season-month-con{
    width:100%;
}

@media screen and (max-width:370px) {
    .season-con {
        margin: 0.6em;
    }
}

/* .bullet-season{
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #3f46a3;
    box-shadow: 4px 4px 0px rgb(114, 148, 241);
    color:white;
    
    margin:1em;
    border-radius:500px;
    width: 4em;
    height: 4em;
  
} */

.bullet-season {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #3f46a3;
    box-shadow: 4px 4px 0px rgb(114, 148, 241);
    color: white;

    font-weight:300;
    padding: 0.5em;
    width: 100%;
}