.nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 4em;
    padding: 0 2em;
    z-index: 999;
    background-color: #273488;
    font-family: 'Montserrat', sans-serif;
    font-weight: 100;
  
}

.nav-logo {
    color: white;
    font-size: 2.5em
}



@media screen and (max-width:736px) {
    .nav-link-con {
        display: none
    }

}

@media screen and (min-width:737px) {
    .nav-mobile-con {
        display: none;
    }
}