@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;0,800;0,900;1,300;1,500&family=Nanum+Myeongjo:wght@400;700;800&family=Roboto+Mono:wght@400;500&display=swap');
/* font-family: 'Montserrat', sans-serif;
font-family: 'Nanum Myeongjo', serif;
font-family: 'Roboto Mono', monospace; */

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  min-height:100vh;
  display:flex;
  flex-direction: column;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.377);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 128, 128, 0.76);
}

.bordert {
  border: 2px solid red;
}