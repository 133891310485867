.enroll-item{
    margin-bottom:1em;
    font-weight:500;
}

.enroll-txt{
   
}

.enroll-icon{
    background-color: rgba(38, 202, 38, 0.842);
    padding: 0.3em;
    border-radius:100px;
    color:white;
    margin-right:0.5em;
}

.enroll-txt{

}
