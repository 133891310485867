.nav-mobile{
    position:fixed;
    left:0;
    width:100%;
    height:100%;
    color:black;
    background-color: #182988fd;
}

.nav-mobile-btn{

    cursor:pointer;
    text-align: center;
    padding:1.3em 2em;
    color:white;
    text-decoration: none;
    box-shadow:inset 0px -5px 3px #0f1f78;
}


.nav-mobile-btn:hover{
    background-color: #263796;
}

.sandwich{
    color:white;
    font-size:2em;
    padding:0.3em;
    cursor:pointer;
}

/* Active Menu */
.nm-active{
    left:0;
    top:62px;
    transition: 0.5s;

}

.nm-deactive{
    left:0;
    top:62px;
    transition: 0.5s;
    left:-150vw;
}

.s-active{
    background-color: rgba(216, 216, 216, 0.226);
    border-radius:10px;
}