.objective-con {
  padding:5vw;
}

.objective-title-con{
  margin-bottom:2em
}

.objective-desc{
  font-size:var(--font-200);
  font-weight:500;
  width:clamp(1vw, 40vw, 40vw);
}


@media screen and (max-width:815px) {
  .objective-desc{
    width:80vw;
  }
}


