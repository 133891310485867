.about-exp-con{
    background-color: #F5F5F5;
}


.about-degree{
    color:#455eff;
    font-weight:600;
}

