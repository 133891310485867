.interestform-input {
    border-bottom: 2px solid rgba(0, 0, 0, 0.541);
}

.interestform-input:focus {
    outline: none;
}


.interestform-input[type=number]::-webkit-inner-spin-button,
.interestform-input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}