:root {
  --font-250: clamp(1rem, 2.5vw, 3.5rem);

  --font-350: clamp(1.5rem, 5vw, 4rem);

  /* h1 tip */
  --font-80: clamp(0.7rem, 1.5vw, 1.5rem);

  /*  sched days*/
  --font-100: clamp(1rem, 0.9vw, 1.5rem);

  /* schedule */
  --font-130: clamp(1.5em, 1.8vw, 1.4em);

  /* contact */
  --font-150: clamp(0.8em, 1.8vw, 1.7em);

  /* course desc - descriptions */
  --font-170: clamp(1em, 1.7vw, 1.3em);

  /* larger description heavier weight --- objective items*/
  --font-200: clamp(1.1rem, 1.4vw, 1.8rem);

  /* sched month */
  --font-230: clamp(1.5em, 1.5vw, 1.8rem);

  /* h2 tip - * */
  --font-300: clamp(1.8rem, 4vw, 3rem);

  /* banner header */
  /* --font-400: clamp(1.8rem, 7vw, 6rem); */
  --font-400: clamp(1.8rem, 4vw, 6rem);

  /* font price  */
  --font-price: clamp(4em, 3.5vw, 5em);

  /* font price txt
  --font-price-txt: clamp(0.5em,vw, 2m) */

  /* font-footer-link */
  --font-footer-link: clamp(0.9em, 0.8vw, 1.5em)
}

.h1-tip {
  color: #3F46A3;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 1em;
  letter-spacing: clamp(0.1rem, 0.5vw, 0.7rem);
  font-size: var(--font-80);
}

h1 {
  font-weight: 500;
}

h2 {
  font-weight: 700;
  font-size: var(--font-300);
}

h3 {
  font-size: var(--font-170)
}

.h1-ast {
  font-size: var(--font-300);
  margin-right: 0.5em;

  color: #4574F5
}

.text-divider {
  font-size: var(--font-300);
  margin-top: -0.5em;
  color: rgb(83, 172, 255);
  letter-spacing: -2px;
}

.fas {
  margin: 0 0.5em
}

a {
  text-decoration: none;
}

.div-fill{
  height:95vh;
}

