.reservation-con {
    background: url('./assets/interestformbg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height:100vh;
    margin-top:4em;
}


@media screen and (max-width:850px){
    .reservation-con{
        flex-direction: column;
    }
}


.enrollment-card, .interestform-module, .interestform-success{
   margin:1em;
}