.banner-con {

    background: url('./assets/banner-bg.png') center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.banner-header-con {
    padding:0 10%;
}

.banner-headers>h1 {
    font-size: var(--font-400);
    font-family: 'Montserrat', sans-serif;
}

.banner-con-btn {
    width: fit-content;
    margin-right: 1em;
    padding: 0.5em 0.7em;
    border-radius: 20px;
    font-size: var(--font-150);
    font-weight: 500;
    color: white;
    cursor: pointer;
    background-color: #4574F5;
}

.banner-con-btn:hover {
    background-color: #5a81ec;
}

.banner-contact-con,
.banner-email-con {
    margin-bottom: 1em;
}

.banner-email-con,
.banner-phone-con>* {
    font-size: var(--font-150)
}



.banner-logo{
    width:70%;
}

@media screen and (max-width:1024px) {
    .banner-con{
        margin-top:4em;
        flex-direction: column-reverse;
    }

    .banner-logo{
        width:40%;
    }
    .banner-logo-con{
    
        align-items:center;
        display:flex;
        justify-content: center;
        text-align: center;
    }
}