.nav-btn {
    padding: 0.5em 0.8em;
    margin-left:1em;

    color: white;
    font-weight: 600;

    outline: none;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 1.2em;
}



.nav-btn:hover {
    color: rgb(49, 152, 221);
}


.enroll-btn {
    background-color: #4574F5;
    border-radius: 20px;
    
}

.enroll-btn:hover {
    color: white;
    background-color: #5a81ec;
}