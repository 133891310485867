.interestform-module {
    background-color: white;
    border: 5px solid white;
    overflow: hidden;
    width: fit-content;
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.308);
    width: 25em;
}

.interestform-header {
    font-size: var(--font-300);
    color: white;
    padding: 0.5em;
    text-align: center;
    background: linear-gradient(to bottom left, #678ef8, rgb(85, 175, 248), #4574F5);
}

.interestform-input-con {
    padding: 0.9em;
}

.interestform-input,
textarea,
.interestform-time-con {
    font-size: var(--font-100);
    padding: 0.5em 1em;
    border: none;
    resize: none;
    margin-top: 2em;
    width: 100%;
}

textarea {
    height: 5em;
    border: none;
    background-color: rgba(128, 128, 128, 0.103);
    border-radius: 5px;
}

/* Error Item */
.err-item {
    color: rgb(250, 250, 250);
    font-weight: 500;
    letter-spacing: 2px;
    background-color: rgb(199, 106, 106);
    padding: 0.5em;
    font-size: 0.9em;
    border-radius: 5px;
    margin-top: 8px;
    text-align: center;
    border-top: 5px solid rgb(245, 47, 47);
}


/* Loading */
.interestform-loading {
    align-self: center;
    padding: 2em 2em;
    animation: load-rotate 3s infinite linear;
    width: 5em;
    height: 5em;

    border-top: 8px solid #2587f0;
    border-left: 5px solid rgba(128, 128, 128, 0.678);
    border-bottom: 5px solid rgba(128, 128, 128, 0.678);
    border-right: 5px solid rgba(128, 128, 128, 0.678);
    border-radius: 100px;
}


@keyframes load-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg)
    }
}



@media screen and (max-width:520px) {
    .interestform-module {
        width: 90%;
    }
}