.objective-item{
    margin:1.2em 0;
    font-size:var(--font-170);
    font-weight:400;
}

.objective-check{
    background-color: #b5ebfc;
    font-size:var(--font-170);
    padding:0.5em;
    border-radius:200px;
    margin-right:20px;
}

