.interestform-loading {
    align-self: center;
    padding: 2em 2em;
    animation: load-rotate 3s infinite linear;
    width: 5em;
    height: 5em;

    border-top: 8px solid #2587f0;
    border-left: 5px solid rgba(128, 128, 128, 0.678);
    border-bottom: 5px solid rgba(128, 128, 128, 0.678);
    border-right: 5px solid rgba(128, 128, 128, 0.678);
    border-radius: 100px;
}


@keyframes load-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg)
    }
}